<template>
  <div id="box">
    <h1>VR心理健康系统</h1>
    <div id="palnBox">
      <div class="textBox"><div>VR心理诊疗方案管理</div></div>
      <div id="formBox">
        <el-form :model="list" ref="list" class="form1">
          <el-form-item>
            <el-input
              class="input1"
              placeholder="方案名称"
              autocomplete="off"
              v-model="list.appName"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="input1"
              placeholder="时间(分钟）"
              autocomplete="off"
              v-model="list.data"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-badge value="该模块默认为A1">
              <el-input
                disabled
                class="input2"
                autocomplete="off"
                v-model="list.appId"
              ></el-input>
            </el-badge>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="adds" class="addBtn"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
        <!-- 方案选择下拉框 -->
        <div id="form2">
          <template>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in VrCare"
                :key="item.id"
                :label="item.appName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button type="danger" @click="delOne(value)">删除</el-button>
            <el-button @click="getPatientOne(value)">查看</el-button>
          </template>
        </div>
        <!-- 方案详情 卡片组件 -->
        <el-card class="box-card">
          <h1>方案详情</h1>
          <div class="text item">方案名称: {{ lookOne.appName }}</div>
          <div class="text item">治疗时长: {{ lookOne.data }}</div>
          <div class="text item">应用编号: {{ lookOne.appId }}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http/axios";
import { Message } from "element-ui";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("VRPlan");
import api from "../http/api";
export default {
  data() {
    return {
      dialogVisible: false,
      input: "",
      value: "",
      lookOne: {
        name: "",
        time: "",
        modular: "",
        type: "",
      },
      list: {
        appId: "A1",
        appName: "",
        data: "",
        tname: "A1",
        type: "A1",
      },
      id: "",
      form: [],
    };
  },
  mounted() {},
  computed: {
    ...mapState(["data", "modularData"]),
    // 筛选Vr治疗模块的数据
    VrCare() {
      return this.data.filter((item) => item.appId == "A1");
    },
  },
  created() {
    this.getAllVRPlan();
  },

  methods: {
    ...mapActions(["getAllVRPlan", "addVRPlan", "delVRPlan", "updateCal"]),

    //   点击修改的弹出框方法
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 新增按钮
    async adds() {
      const data = await api.VRPlan.addVRPlan(this.list);
      if (data.data.msg == "请求处理正常") {
        Message.success("新增成功");
        this.getAllVRPlan();
        // console.log(22222222, data);
      } else {
        Message.warning("新增失败，请重新输入");
      }
    },
    // 删除
    delOne(id) {
      // console.log("id", id);
      // 删除提示框
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.delVRPlan(id);
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取某一个患者数据
    async getPatientOne(_id) {
      const { data } = await axios({
        url: `/CareType/?_id=${_id}`,
        method: "get",
        params: {
          _id,
        },
      });
      if (data.status == 0) {
        this.lookOne = data.data;
        // console.log("获取id", this.lookOne);
      }
    },
    //     // 确定修改
    //     updateBtn(newData) {
    //       this.updateCal({
    //         id: newData,
    //         name: this.lookOne.name,
    //         age: this.lookOne.age,
    //         sex: this.lookOne.sex,
    //         CID: this.lookOne.CID,
    //         phone: this.lookOne.phone,
    //         address: this.lookOne.address,
    //       });
    //       this.updateCal(this.lookOne);
    //       Message.success("修改成功");
    //       window.location.reload();
    //     },
    //     noUpdate() {
    //       this.dialogVisible = false;
    //       Message.info("已取消修改");
    //     },
  },
};
</script>
<style lang='scss' scoped>
.text {
  font-size: 14px;
}

.box-card {
  width: 480px;
  div {
    margin: 20px;
  }
  h1 {
    width: 100%;
    text-align: center;
  }
}
#palnBox {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  margin: 40px;
}
.box-card {
  width: 590px;
  border-radius: 20px;
  margin: 10px;
  margin-left: 40px;
}
#formBox {
  height: 100%;
  // background: #000;
}
.textBox {
  width: 200px;
  text-align: center;
  height: 500px;
  display: flex;
  align-items: center;
  div {
    border-radius: 50px;
    width: 200px;
    line-height: 70px;
    height: 70px;
    background: cornflowerblue;
  }
}
.form1,
#form2 {
  display: flex;
  flex-wrap: wrap;
  width: 650px;
  margin: 10px;
  margin-left: 40px;
  .el-form-item {
    height: 50px;

    display: flex;
    -ms-flex-item-align: center;
    margin: 5px;
    .el-input {
      width: 120px;
      margin-top: 10px;
    }
    .input1 {
      width: 100px;
    }
    .input2 {
      width: 70px;
    }
    // .el-badge__content.is-fixed {
    //   position: absolute;
    //   top: -3px;
    //   right: 150px;
    //   transform: translateY(-50%) translateX(100%);
    // }
  }
  .el-select {
    width: 465px;
  }
}
.addBtn {
  margin-top: 10px;
}
// #box {
//   // height: 100%;
//   // width: 100%;
 
// }
</style>
